// 240614 개인정보처리방침

import { Component } from "react";

// 다국어 설정
import RegexText from "../../../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../../../context/LocaleContext";

export default class Term05 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <LocaleConsumer>
        {(context) => {
          const lc = context.lc;

          return (
                <>
                    <div className='TermsText'>{lc.policy2.subtitle}</div>
                    <div className='TextBox'>
                        <div className='Text01'>{lc.policy2.text_1}</div>
                        <div className='Text02'>{lc.policy2.text_2}</div>
                        <div className='Text03'>{lc.policy2.text_3}</div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_1}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy2.content_1_1}</div>
                            <table className="Horizontal">
                                <colgroup>
                                    <col width="24%" />
                                    <col width="38%" />
                                    <col width="38%" />
                                </colgroup>
                                <tr>
                                    <th>{lc.policy2.content_1_th1}</th>
                                    <th>{lc.policy2.content_1_th2}</th>
                                    <th>{lc.policy2.content_1_th3}</th>
                                </tr>
                                <tr>
                                    <td>{lc.policy2.content_1_td1_1}</td>
                                    <td>{lc.policy2.content_1_td1_2}</td>
                                    <td>{lc.policy2.content_1_td1_3}</td>
                                </tr>
                                <tr>
                                    <td>{lc.policy2.content_1_td2_1}</td>
                                    <td>{lc.policy2.content_1_td2_2}</td>
                                    <td>{lc.policy2.content_1_td2_3}</td>
                                </tr>
                                <tr>
                                    <td>{lc.policy2.content_1_td3_1}</td>
                                    <td>{lc.policy2.content_1_td3_2}</td>
                                    <td>{lc.policy2.content_1_td3_3}</td>
                                </tr>
                            </table>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='SmallText'>{lc.policy2.content_1_2}</div>
                            </div>
                        </div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_2}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy2.content_2_1}</div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_2_1_1}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_2_1_2}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_2_1_3}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_2_1_4}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_2_1_5}</div>
                            </div>
                        </div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_3}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_3_1, "mr10")}</div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_1_1}</div>
                            </div>                            
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_1_2}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_1_3}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_1_4}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_1_5}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_1_6}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_1_7}</div>
                            </div>
                            <div className='Text01 h-box mt20'>{RegexText(lc.policy2.content_3_2, "mr10")}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_3_3, "mr10")}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_3_4, "mr10")}</div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_4_1}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_4_2}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_4_3}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_4_4}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_4_5}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_4_6}</div>
                            </div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_3_4_7}</div>
                            </div>
                        </div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_4}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy2.content_4_1}</div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_4_1_1}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_4_1_2}</div>
                            </div>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_4_1_3}</div>
                            </div>
                            <div className='Text01 mt20'>{lc.policy2.content_4_2}</div>
                        </div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_5}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy2.content_5_1}</div>
                            <table className="Horizontal">
                                <colgroup>
                                    <col width="24%" />
                                    <col width="36%" />
                                    <col width="40%" />
                                </colgroup>
                                <tr>
                                    <th>{lc.policy2.content_5_th1}</th>
                                    <th>{lc.policy2.content_5_th2}</th>
                                    <th>{lc.policy2.content_5_th3}</th>
                                </tr>
                                <tr>
                                    <td>{lc.policy2.content_5_td1_1}</td>
                                    <td>{lc.policy2.content_5_td1_2}</td>
                                    <td>{lc.policy2.content_5_td1_3}</td>
                                </tr>
                                <tr>
                                    <td>{lc.policy2.content_5_td2_1}</td>
                                    <td>{lc.policy2.content_5_td2_2}</td>
                                    <td>{lc.policy2.content_5_td2_3}</td>
                                </tr>
                            </table>
                            <div className='Text01 mt20'>{lc.policy2.content_5_2}</div>
                            <div className='Text01 mt20'>{lc.policy2.content_5_3}</div>
                        </div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_6}</div>
                        <div className='TextSmallBox'>
                        <div className='Text01 h-box mt20'>{RegexText(lc.policy2.content_6_1, "mr10")}</div>
                            <table className="ml26">
                                <colgroup>
                                    <col width="250px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>{lc.policy2.content_6_th1}</th>
                                    <td>{lc.policy2.content_6_td1}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_6_th2}</th>
                                    <td>{lc.policy2.content_6_td2}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_6_th3}</th>
                                    <td>{lc.policy2.content_6_td3}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_6_th4}</th>
                                    <td>{lc.policy2.content_6_td4}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_6_th5}</th>
                                    <td>{lc.policy2.content_6_td5}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_6_th6}</th>
                                    <td>{lc.policy2.content_6_td6}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_6_th7}</th>
                                    <td>{lc.policy2.content_6_td7}</td>
                                </tr>
                            </table>
                            <div className='TextBox02 ml30 h-box'>
                                <div className='SmallText'>{lc.policy2.content_6_2}</div>
                            </div>
                            <div className='Text01 mt50 h-box'>
                                <span className="flex-1">{lc.policy2.content_6_3}</span>
                                <span className="pr50">{lc.policy2.content_6_4}</span>
                                <span>{lc.policy2.content_6_5}</span>
                            </div>
                        </div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_7}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy2.content_7_1}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_7_1_1, "mr10")}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_7_1_2, "mr10")}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_7_1_3, "mr10")}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_7_1_4, "mr10")}</div>                            
                        </div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_8}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy2.content_8_1}</div>
                            <div className='Text01 h-box mt20'>{RegexText(lc.policy2.content_8_2, "mr10")}</div>
                            <div className='Text01'>{lc.policy2.content_8_2_1}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_8_3, "mr10")}</div>  
                            <div className='Text01'>{lc.policy2.content_8_3_1}</div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_8_3_2}</div>
                            </div>   
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_8_3_3}</div>
                            </div>                  
                        </div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_9}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy2.content_9_1}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_9_1_1, "mr10")}</div>
                            <div className='Text01'>{lc.policy2.content_9_1_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_9_2_1, "mr10")}</div>  
                            <div className='Text01'>{lc.policy2.content_9_2_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_9_3_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy2.content_9_3_2}</div>
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_9_3_3}</div>
                            </div>   
                            <div className='TextBox02 h-box'>
                                <div className='Acc'>•</div>
                                <div className='SmallText'>{lc.policy2.content_9_3_4}</div>
                            </div>                  
                        </div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_10}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy2.content_10_1}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_10_1_1, "mr10")}</div>
                            <div className='Text01'>{lc.policy2.content_10_1_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_10_2_1, "mr10")}</div>  
                            <div className='Text01'>{lc.policy2.content_10_2_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_10_3_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy2.content_10_3_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_10_4_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy2.content_10_4_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_10_5_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy2.content_10_5_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_10_6_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy2.content_10_6_2}</div>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_10_7_1, "mr10")}</div> 
                            <div className='Text01'>{lc.policy2.content_10_7_2}</div>                                       
                        </div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_11}</div>                        
                        <div className='TextSmallBox'>
                            <div className='Text01 h-box'>{RegexText(lc.policy2.content_11_1, "mr10")}</div>
                            <div className='TextBlue'>{lc.policy2.content_11_3}</div>
                            <table className="ml26">
                                <tr>
                                    <th>{lc.policy2.content_11_4}</th>
                                    <td>{lc.policy2.content_11_5_3}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_11_6}</th>
                                    <td>{lc.policy2.content_11_7_3}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_11_8}</th>
                                    <td>{lc.policy2.content_11_9}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_11_10}</th>
                                    <td>{lc.policy2.content_11_11_1}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_11_12}</th>
                                    <td>{lc.policy2.content_11_13_3}</td>
                                </tr>
                            </table>

                            <div className='TextBlue'>{lc.policy2.content_11_14}</div>
                            <table className="ml26">
                                <tr>
                                    <th>{lc.policy2.content_11_15}</th>
                                    <td>{lc.policy2.content_11_16_1}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_11_17}</th>
                                    <td>{lc.policy2.content_11_17_1}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_11_18}</th>
                                    <td>{lc.policy2.content_11_18_1}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_11_19}</th>
                                    <td>{lc.policy2.content_11_19_1}</td>
                                </tr>
                                <tr>
                                    <th>{lc.policy2.content_11_20}</th>
                                    <td>{lc.policy2.content_11_20_1}</td>
                                </tr>
                            </table>
                            <div className='Text01 h-box mt30'>{RegexText(lc.policy2.content_11_21, "mr10")}</div>
                        </div>
                    </div>
                    <div className='LineG' />

                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_12}</div>
                        <div className='TextSmallBox'>
                            <div className='Text01'>{lc.policy2.content_12_1}</div>
                            <div className='TextBlue' style={{ marginLeft: "50px" }}>
                                {lc.policy2.content_12_2}
                            </div>
                            <div className='TextBox02'>
                                <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                                    {RegexText(lc.policy2.content_12_3, "mr10")}
                                </div>
                            </div>
                            <div className='TextBlue' style={{ marginLeft: "50px" }}>
                                {lc.policy2.content_12_4}
                            </div>
                            <div className='TextBox02'>
                                <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                                {RegexText(lc.policy2.content_12_5, "mr10")}
                                </div>
                            </div>
                            <div className='TextBlue' style={{ marginLeft: "50px" }}>
                                {lc.policy2.content_12_6}
                            </div>
                            <div className='TextBox02'>
                                <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                                    {RegexText(lc.policy2.content_12_7, "mr10")}
                                </div>
                            </div>
                            <div className='TextBlue' style={{ marginLeft: "50px" }}>
                                {lc.policy2.content_12_8}
                            </div>
                            <div className='TextBox02'>
                                <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                                    {RegexText(lc.policy2.content_12_8_1, "mr10")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='LineG' />
                    
                    <div className='TermsAllBox'>
                        <div className='TermsTitle'>{lc.policy2.title_13}</div>
                        <div className='TextSmallBox'>
                                <div className='Text01'>{lc.policy2.content_13_1}</div>
                        </div>
                    </div>
                    <div className='LineG' />

                </>
            );
        }}
      </LocaleConsumer>
    );
  }
}
